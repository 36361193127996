import { useRef } from 'react';
import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PaymentsFeatureFlags, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentLoader,
  CopyToClipboardButton,
  Heading,
  Modal,
  Button,
  Text,
  BannerNotification,
} from '@frontend/design-system';
import { isPaidInvoice } from '../../utils';
import { LargeInfo } from './large-info';
import { PaymentMethods } from './payment-methods';
import { PaymentSummary } from './payment-summary';

const styles = {
  summary: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
  iconButton: css`
    text-transform: none;
  `,
  icon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  `,
  divider: css`
    display: flex;
    align-items: center;
  `,
  orText: css`
    margin: ${theme.spacing(1, 1)};
  `,
  paymentMethods: (newUI: boolean) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${theme.spacing(20)}, 1fr));
    align-content: stretch;
    grid-gap: ${theme.spacing(1)};
    ${newUI &&
    `
      background-color: ${theme.colors.neutral5};
      padding: ${theme.spacing(2)};
    `}
    & > button {
      height: ${theme.spacing(8)};
      ${newUI &&
      `
        background-color: ${theme.colors.white};
        span { flex-direction: column; }
      `}
    }
  `,
  sunbitIcon: (isAcceptableSunbitAmount: boolean) =>
    css`
      ${!isAcceptableSunbitAmount && `opacity: 0.25;`};
    `,
  sunbitButton: css`
    display: flex;
    gap: ${theme.spacing(1)};
  `,
  sunbitButtonTitle: css`
    display: flex;
    align-items: center;
  `,
  modalActions: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(2)};
    justify-content: end;
    gap: ${theme.spacing(2)};
    align-items: center;
  `,
  alreadyPaidBanner: {
    container: css`
      background-color: ${theme.colors.white};
    `,
    banner: css`
      margin: ${theme.spacing(2, 2, 0, 2)};
    `,
  },
};

export const PaymentFlowList = () => {
  const { t } = useTranslation('payments');
  const { invoice, isLoading } = useSelectedInvoice();
  const { closeModal } = useMultiStepModal();
  const { getLocationName, allLocations, locationId } = useMultiQueryUtils();
  const { aggregateValue: newFlowUI, isLoading: isFlagLoading } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: [locationId],
  });

  const parentRef = useRef<HTMLDivElement>(null);
  const isInvoicePaid = isPaidInvoice(invoice);

  if (isLoading || !invoice || isFlagLoading) return <ContentLoader show={true} />;

  return (
    <>
      <Modal.Body
        css={
          newFlowUI
            ? {
                padding: theme.spacing(0),
                backgroundColor: theme.colors.neutral5,
                gap: theme.spacing(0),
              }
            : {}
        }
      >
        {isInvoicePaid && (
          <div css={styles.alreadyPaidBanner.container}>
            <BannerNotification
              css={styles.alreadyPaidBanner.banner}
              fullWidth
              status='success'
              message={t('This payment has already been made successfully')}
            />
          </div>
        )}
        {newFlowUI ? (
          <PaymentSummary invoice={invoice} />
        ) : (
          <>
            <div css={styles.summary}>
              <LargeInfo title={t('Customer')} info={invoice?.person.name} />
              <div css={styles.amount} ref={parentRef}>
                <LargeInfo title={t('Amount Owed')} info={formatCentsToCurrency(invoice?.billedAmount)} />
                <LargeInfo title={t('Payment Due')} info={formatDate(invoice?.billedAt, 'MMMM D, YYYY')} />
                {allLocations.length > 1 && (
                  <LargeInfo
                    title={t('Location')}
                    info={
                      <Chip.SingleChip containerRef={parentRef} isResponsive>
                        {getLocationName(invoice?.locationId)}
                      </Chip.SingleChip>
                    }
                  />
                )}
              </div>
            </div>
            <Divider color={theme.colors.neutral20} css={{ margin: 0 }} />
            <Heading level={3}>{t('Select Payment Method')}</Heading>
          </>
        )}
        <PaymentMethods modernUI={newFlowUI} invoice={invoice} />
      </Modal.Body>
      <Modal.Footer>
        <div
          css={{
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
            justifyContent: newFlowUI ? 'space-between' : 'end',
          }}
        >
          <CopyToClipboardButton
            variant='tertiary'
            textToCopy={invoice?.links.payment ?? ''}
            tooltipText={t('Copy link')}
            tooltipSuccessText={t('Link copied!')}
            hasIcon={newFlowUI}
            hasSuccessTooltip
            disabled={isInvoicePaid}
            icon={() => <Icon name='link-small' color={isInvoicePaid ? 'disabled' : 'primary'} size={24} />}
          >
            {newFlowUI ? (
              <Text weight='bold' color={isInvoicePaid ? 'disabled' : 'primary'}>
                {t('Copy Link')}
              </Text>
            ) : (
              t('Copy payment link')
            )}
          </CopyToClipboardButton>
          <Button variant='secondary' onClick={() => closeModal()}>
            {isInvoicePaid ? t('Close') : t('Collect Later')}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};
