import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useCustomizationFlagShallowStore, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { PickerLocation } from '@frontend/scope';

export const useHasPhoneSystemAccess = () => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  return flags['phonehomeicon']?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
};

export const useHasPhoneSettingsAccess = ({ selectedLocations }: { selectedLocations: PickerLocation[] }) => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const hasPhonesCustomizationFlag =
    flags['phonehomeicon']?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  const candidateLocation =
    selectedLocations.length > 0 ? selectedLocations[0] : { locationType: '', locationID: '', parentID: '' };
  const locationIds = selectedLocations.map((location) => location.locationID);

  const hasPhoneFullAccessAcl =
    candidateLocation.locationType === 'unify'
      ? hasSchemaACL(candidateLocation.parentID, Permission.PHONE_FULL_ACCESS)
      : hasSchemaACL(candidateLocation.locationID, Permission.PHONE_FULL_ACCESS);
  const hasFullPhoneAccess = hasPhoneFullAccessAcl && hasPhonesCustomizationFlag;

  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: locationIds ?? [],
  });

  const { aggregateValue: callRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: locationIds ?? [],
  });

  if (!selectedLocations.length) {
    return {
      hasPhoneFullAccessAcl: false,
      hasPhonesCustomizationFlag: false,
      hasFullPhoneAccess: false,
      hasMainLineAccess: false,
      hasDepartmentsAccess: false,
      hasOverridesAccess: false,
      hasOfficeHoursAccess: false,
      hasCallGroupsAccess: false,
      hasCallQueuesAccess: false,
      hasCallRoutesAccess: false,
      hasCallRouteDetailsAccess: false,
      hasCallRoutesAccessNoFeatureFlag: false,
      hasPhoneTreesAccess: false,
      hasVoicemailBoxesAccess: false,
      hasAudioLibraryAccess: false,
      hasCallBlockingAccess: false,
      hasCallRecordingAccess: false,
      hasHoldMusicAccess: false,
      hasDeviceSettingsAccess: false,
      hasE911AddressesAccess: false,
      hasContactDirectoriesAccess: false,
      hasLineKeysAccess: false,
    };
  }

  const phoneAccess = {
    hasPhoneFullAccessAcl,
    hasPhonesCustomizationFlag,
    hasFullPhoneAccess,
    // Below are the route specific access checks for phone settings routes
    hasMainLineAccess: departmentsFlag && hasPhoneFullAccessAcl,
    hasDepartmentsAccess: departmentsFlag && hasPhoneFullAccessAcl,
    hasOverridesAccess: hasPhonesCustomizationFlag,
    hasOfficeHoursAccess: true,
    hasCallGroupsAccess: hasFullPhoneAccess,
    hasCallQueuesAccess: hasFullPhoneAccess,
    hasCallRoutesAccess: callRoutesFlag && hasFullPhoneAccess,
    hasCallRouteDetailsAccess: callRoutesFlag && hasFullPhoneAccess,
    // hasCallRoutesAccessNoFeatureFlag is for a temporary workaround to allow visiting the page through URL without the feature flag
    hasCallRoutesAccessNoFeatureFlag: hasFullPhoneAccess,
    hasPhoneTreesAccess: hasFullPhoneAccess,
    hasVoicemailBoxesAccess: hasPhonesCustomizationFlag,
    hasAudioLibraryAccess: hasPhonesCustomizationFlag,
    hasCallBlockingAccess: hasFullPhoneAccess,
    hasCallRecordingAccess: hasFullPhoneAccess,
    hasHoldMusicAccess: hasFullPhoneAccess,
    hasDeviceSettingsAccess: hasFullPhoneAccess,
    hasE911AddressesAccess: hasFullPhoneAccess,
    hasContactDirectoriesAccess: hasFullPhoneAccess,
    hasLineKeysAccess: hasFullPhoneAccess,
  };

  return phoneAccess;
};
