import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';

type Props = {
  locationId: string;
  hasQuickFillAccess: boolean;
};

export const DropdownOptionContent = ({ locationId, hasQuickFillAccess }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const { getLocationName } = useAppScopeStore();
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  if (hasQuickFillAccess) {
    return <Text>{getLocationName(locationId)}</Text>;
  }

  return (
    <>
      <Text {...triggerProps} color='subdued'>
        {getLocationName(locationId)}
      </Text>
      <Tooltip {...tooltipProps} css={tooltipStyle}>
        <Text size='medium' color='white'>
          {t("This location doesn't have Quick Fill.")}
        </Text>
      </Tooltip>
    </>
  );
};

const tooltipStyle = css`
  background-color: ${theme.colors.neutral90};
  padding: ${theme.spacing(1, 3, 1, 1)};
  max-width: 220px;
`;
