import { useCallback } from 'react';
import { ThreadStatus } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { DepartmentsQueries } from '@frontend/api-departments';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { usePopoverMenu } from '@frontend/design-system';
import { DepartmentSelectorState } from '../components';

type UseDepartmentSelectorArgs = {
  groupId: string;
  departmentId?: string;
  alwaysShow?: boolean;
  personPhone?: string;
};

export const useDepartmentSelector = ({
  groupId,
  departmentId,
  alwaysShow = false,
  personPhone,
}: UseDepartmentSelectorArgs): DepartmentSelectorState => {
  const {
    data: { smsNumbers: departments },
  } = DepartmentsQueries.useListDefaultSMSQuery(
    {
      locationId: groupId,
    },
    {
      enabled: !!groupId,
    }
  );
  const departmentIds =
    departments?.reduce<string[]>((acc, { id }) => {
      if (id) acc.push(id);
      return acc;
    }, []) ?? [];
  const selectedDepartment = departments?.find(({ id }) => id === departmentId);

  const threadStatusFFQuery = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
    flagName: 'nwx:thread-status-endpoints',
    groupIds: [groupId],
  });
  const shouldUseThreadStatusEndpoints = !!threadStatusFFQuery.data?.[groupId];

  const reduceThreadsListToDepartmentIds = useCallback((data: (typeof existingThreadsQuery)['data']) => {
    return (
      data?.pages.reduce<string[]>((acc, page) => {
        if (!!page.threads.length) acc.push(...page.threads.map(({ departmentId }) => departmentId));
        return acc;
      }, []) ?? []
    );
  }, []);

  const popoverState = usePopoverMenu({
    placement: 'bottom',
  });

  // TODO: this needs to use the new endpoint in order to group properly with new segmented pagination BE strategy
  const existingThreadsQuery = SMSDataV3.Queries.useListThreadsQuery({
    request: {
      locationId: groupId,
      groupIds: [groupId],
      threadLimit: 500,
      personPhones: personPhone ? [formatPhoneNumberE164(personPhone)] : [],
      departmentIds,
    },
    options: {
      enabled:
        !!personPhone &&
        !!departments?.length &&
        popoverState.isOpen &&
        !threadStatusFFQuery.isLoading &&
        !shouldUseThreadStatusEndpoints,
    },
  });
  const threadStatusQuery = SMSDataV3.Queries.useBatchGetThreadStatusQuery({
    request: {
      requests: SMSDataV3.Utils.calculatePossibleThreadStatusRequests({
        personPhones: personPhone ? [personPhone] : [],
        departmentIdsPerGroupId: { [groupId]: departmentIds },
        groupIds: [groupId],
      }),
    },
    options: {
      enabled: !!personPhone && !threadStatusFFQuery.isLoading && shouldUseThreadStatusEndpoints,
    },
  });

  const departmentIdsWithActiveConversations = shouldUseThreadStatusEndpoints
    ? threadStatusQuery.data?.responses
        .filter((response) => response.status === ThreadStatus.ACTIVE)
        .map((response) => response.departmentId) ?? []
    : reduceThreadsListToDepartmentIds(existingThreadsQuery.data);
  const departmentIdsWithArchivedConversations = shouldUseThreadStatusEndpoints
    ? threadStatusQuery.data?.responses
        .filter((response) => response.status === ThreadStatus.ARCHIVED)
        .map((response) => response.departmentId) ?? []
    : [];
  const departmentIdsWithoutConversations = shouldUseThreadStatusEndpoints
    ? threadStatusQuery.data?.responses
        .filter((response) => response.status === ThreadStatus.NEW)
        .map((response) => response.departmentId) ?? []
    : existingThreadsQuery.data
    ? departmentIds.filter((id) => !departmentIdsWithActiveConversations.includes(id))
    : [];
  const showButton = alwaysShow || ((departments?.length ?? 0) > 1 && !!departmentId);

  return {
    showButton,
    selectedDepartment,
    popoverState,
    personPhone,
    departments,
    departmentIdsWithArchivedConversations,
    departmentIdsWithActiveConversations,
    departmentIdsWithoutConversations,
  };
};
