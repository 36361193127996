import {
  LocationFeatureFlagSettingName,
  LocationFeatureSettings,
} from '@weave/schema-gen-ts/dist/schemas/payments/messages/location_settings/location_settings.pb';

export enum PaymentsFeatureFlags {
  PaymentWritebacks = 'Payment Writeback',
  PaymentEaglesoftWritebacks = 'Payment Eaglesoft Writeback',
  TtpAuthentication = 'payments:ttp-attachment-authentication',
  TtpAuthenticationToggle = 'payments:ttp-authentication-ff-toggle',
  ShowStripeAffirmPaymentToggle = 'payments:show-stripe-affirm-payment-toggle',
  ShowStripeAffirmPaymentOption = 'payments:show-stripe-affirm-payment-option',
  PaymentsMultiLocationFeature = 'Payments_multi_location_feature',
  openDentalWritebacks = 'payments-opendental-writebacks',
  openDentalCloudWritebacks = 'payments-opendental-cloud-writebacks',
  showEaglesoftWritebacksToggle = 'payments-eaglesoft-writebacks-toggle',
  showFuseWritebacksToggle = 'payments-fuse-writebacks-toggle',
  PaymentDrChronoWritebacks = 'Payment DrChrono Writeback',
  showDrChronoWritebacksToggle = 'payments-dr-chrono-writebacks-toggle',
  PaymentLinkMessaging = 'payments:payment-link-messaging',
  ACHOnFile = 'payments:ach-on-file',
  EnableLeftShiftingMoneyFields = 'payments-left-shifting-money-fields',
  EnableAddACH = 'payments-enable-add-ach',
  EnableACHBalanceCheck = 'payments:enable-ach-balance-check',
  EnableJustifyOnboarding = 'payments:platform-aci-justifi-onboarding:enabled',
  canUseSyncCreateIntentEndpoint = 'payments:nwx:sync-create-intent-endpoint',
  CollectPaymentOnABTest = 'collect-payment-ON-a-b-test',
  UseMultiProcessorCollect = 'payments:use-multi-processor-collect',
  NewCollectionRequestModal = 'payments:new-collection-request-modal',
  AutoDetectPMSToggles = 'payments:nwx:auto-detection-of-pms-toggles',
}

export const paymentsLocationFeatureSettings: Partial<
  Record<LocationFeatureFlagSettingName, keyof LocationFeatureSettings>
> = {
  [LocationFeatureFlagSettingName.UNSPECIFIED]: 'locationId',
  [LocationFeatureFlagSettingName.EAGLESOFT_WRITEBACKS_ENABLED]: 'eaglesoftWritebacksEnabled',
  [LocationFeatureFlagSettingName.DR_CHRONO_WRITEBACKS_ENABLED]: 'drChronoWritebacksEnabled',
  [LocationFeatureFlagSettingName.OPEN_DENTAL_WRITEBACKS_ENABLED]: 'openDentalWritebacksEnabled',
  [LocationFeatureFlagSettingName.TTP_AUTH_ENABLED]: 'ttpAuthEnabled',
  [LocationFeatureFlagSettingName.FUSE_WRITEBACKS_ENABLED]: 'fuseWritebacksEnabled',
  [LocationFeatureFlagSettingName.OPEN_DENTAL_CLOUD_WRITEBACKS_ENABLED]: 'openDentalCloudWritebacksEnabled',
};
