import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v2/beta/override_service.pb';
import { StateCreator } from 'zustand';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export type PhoneOverrideStoreV2 = {
  overridesV2: PhoneOverride[];
  setOverridesV2: (overrides: PhoneOverride[]) => void;
};

const storeDefinition: StateCreator<
  PhoneOverrideStoreV2,
  [['zustand/subscribeWithSelector', never], ['zimmer', never], ['zustand/devtools', never]]
> = (set) => ({
  overridesV2: [],
  setOverridesV2: (overridesV2: PhoneOverride[]) => {
    set({ overridesV2 });
  },
});

export const usePhoneOverrideStoreV2 = createStoreWithSubscribe<PhoneOverrideStoreV2>(storeDefinition, {
  name: 'PhoneOverrideStoreV2',
  trace: true,
});

export const usePhoneOverrideShallowStoreV2 = createShallowStore(usePhoneOverrideStoreV2);
