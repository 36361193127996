import { ComponentProps, FC, ReactElement, cloneElement } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { AdaptoActions } from '@frontend/adapto-actions';
import { theme } from '@frontend/theme';
import { useFeatureUpgradeDetailsModal } from '../../hooks/useFeatureUpgradeDetailsModal';
import { useFormsTeaserConfig } from '../../hooks/useFormsTeaserConfig';
import { FeatureUpgradeIcon } from '../feature-upgrade-icon/FeatureUpgradeIcon';
import { FeatureUpgradeLabel } from '../feature-upgrade-label/FeatureUpgradeLabel';
import { FeatureUpgradeModalWrapper } from '../feature-upgrade-modal-wrapper/FeatureUpgradeModalWrapper';

interface FormsPromotionActionButtonWrapperProps {
  locationId: string;
  children: ReactElement<ComponentProps<typeof AdaptoActions.Action>>;
  showFeaturePromotion?: boolean;
  onUpgradeSuccess: () => void;
  source?: string;
}

export const FormsPromotionActionButtonWrapper: FC<FormsPromotionActionButtonWrapperProps> = ({
  children,
  locationId,
  showFeaturePromotion,
  source = '',
  onUpgradeSuccess,
}) => {
  const { actionLabel, showFormsTeaser, trackingId } = useFormsTeaserConfig({
    locationIds: showFeaturePromotion && locationId ? [locationId] : [],
    checkBundleUpgrade: true,
    source,
  });

  const { featureUpgradeDetailsModalControls } = useFeatureUpgradeDetailsModal({
    feature: Feature.FORMS,
  });

  if (!showFeaturePromotion) {
    return children;
  }

  if (!showFormsTeaser) {
    return cloneElement(children, {
      disabled: true,
    });
  }

  const childWithPromoEvent = cloneElement(children, {
    ...featureUpgradeDetailsModalControls.triggerProps,
    trackingId,
    'aria-label': actionLabel,
    label: <FeatureUpgradeLabel label={actionLabel} />,
  });

  return (
    <FeatureUpgradeModalWrapper
      feature={Feature.FORMS}
      onUpgradeSuccess={onUpgradeSuccess}
      modalControls={featureUpgradeDetailsModalControls}
    >
      <div css={[buttonContainerStyles, iconTriggerStyles]}>
        {childWithPromoEvent}
        <FeatureUpgradeIcon css={upgradeIconStyles} />
      </div>
    </FeatureUpgradeModalWrapper>
  );
};

const upgradeIconStyles = css({
  position: 'absolute',
  top: theme.spacing(0.75),
  right: theme.spacing(0.75),
  backgroundColor: theme.colors.white,
  borderRadius: theme.spacing(0.25),
  pointerEvents: 'none',
});

const buttonContainerStyles = css({
  position: 'relative',
});

const iconTriggerStyles = css({
  'img:last-of-type': {
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
  },
  ':hover img:last-of-type': {
    opacity: 1,
  },
});
