import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { CreateAndCollectModal } from '@frontend/payments-invoice-create';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, useModalControl } from '@frontend/design-system';
import { CreatePaymentRequestModal } from '../../PaymentRequests';

const styles = {
  createButton: css`
    width: auto;
    padding-left: ${theme.spacing(1)};
  `,
  plusIcon: css`
    margin-right: ${theme.spacing(1)};
  `,
};

export type NewInvoiceActionProps = {
  trackingId?: string;
};

export const NewInvoiceAction = ({ trackingId }: NewInvoiceActionProps) => {
  const { t } = useTranslation('payments');
  const { selectedLocationIds } = useAppScopeStore();
  const { modalProps: createModalProps, triggerProps: createModalTriggerProps } = useModalControl();

  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: selectedLocationIds,
  });

  return (
    <>
      <Button
        trackingId={trackingId}
        iconName='plus'
        variant='primary'
        size='large'
        css={styles.createButton}
        {...createModalTriggerProps}
      >
        {t('New Invoice')}
      </Button>
      {newRequestUI ? (
        <CreateAndCollectModal {...createModalProps} />
      ) : (
        <CreatePaymentRequestModal {...createModalProps} />
      )}
    </>
  );
};
