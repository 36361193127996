import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useArrayState } from '@frontend/array';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ModalControlModalProps, TableFilters, TextField, useFormField } from '@frontend/design-system';
import { SearchFailedPaymentsOrigin, SearchFailedPaymentsParams } from '../../../hooks/payment-failed';
import { getFailedTransacactionsOriginParamLabels } from '../../../hooks/payment-failed/utils';
import { hasFilter } from '../../../utils';
import { StatusItem as FilterCheckboxItem } from '../../FilterCheckbox';

export interface FailedTransactionsFilterProps {
  initialFilter?: SearchFailedPaymentsParams;
  onApply: (filter: SearchFailedPaymentsParams) => void;
  modalProps: ModalControlModalProps;
  setShowNotification: (showNotification: boolean) => void;
}

const styles = {
  checkBoxStyle: css`
    margin: ${theme.spacing(1, 3)};
    > label {
      margin: 0; /*Because bootstrap */
      margin-left: ${theme.spacing(2)};
    }
  `,
  searchFieldStyle: css`
    margin-bottom: ${theme.spacing(2)};
  `,
};

const DATA_TRACKING_PREFIX = `nwx:failed-transactions:filter`;

const originLabels = getFailedTransacactionsOriginParamLabels();
// const statusLabels = getFailedTransactionsStatusParamLabels();

export const FailedTransactionsFilter = ({
  initialFilter,
  onApply,
  modalProps,
  setShowNotification,
}: FailedTransactionsFilterProps) => {
  const { t } = useTranslation('payments');

  useEffect(() => {
    setShowNotification?.(hasFilter(initialFilter?.search, ['paymentStatus']));
  }, [initialFilter, setShowNotification]);

  // PERSON
  const {
    // paymentStatus: initialPaymentStatus,
    personName: initialPersonName,
    origin: initialPaymentType,
  } = initialFilter?.search || {};

  const textFieldProps = useFormField({ type: 'text', value: initialPersonName });

  // STATUS OPTIONS
  // const [paymentStatus, setPaymentStatus, addPaymentStatus, removePaymentStatus] =
  //   useArrayState<SearchFailedPaymentStatus>(initialPaymentStatus ?? []);

  // ORIGIN OPTIONS
  const [origin, setOrigin, addOrigin, removeOrigin] = useArrayState<SearchFailedPaymentsOrigin>(
    initialPaymentType ?? []
  );

  const handleApply = () => {
    const filter: SearchFailedPaymentsParams = {
      submittedAt: initialFilter?.submittedAt,
      order: initialFilter?.order,
      search: initialFilter?.search || {},
    };

    filter.search.personName = textFieldProps.value;
    filter.search.origin = origin;
    // filter.search.paymentStatus = paymentStatus;

    setShowNotification?.(hasFilter(filter.search, ['paymentStatus']));
    onApply(filter);
  };

  return (
    <TableFilters {...modalProps} onApplyClick={handleApply}>
      <TableFilters.Section
        sectionHeaderLabel='Search'
        disableClear={!textFieldProps.value && !textFieldProps.value}
        onClearClick={() => {
          textFieldProps.onChange({ value: '', name: '' });
        }}
        data-trackingid={`${DATA_TRACKING_PREFIX}-search-fields`}
      >
        <TextField
          name='text-filter'
          label={t('Search Person')}
          placeholder={t('e.g. Person')}
          {...textFieldProps}
          css={styles.searchFieldStyle}
          data-trackingid={`${DATA_TRACKING_PREFIX}-text`}
        />
      </TableFilters.Section>

      <TableFilters.Section
        sectionHeaderLabel={t('Origin')}
        disableClear={!origin.length}
        onClearClick={() => setOrigin([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-origin`}
      >
        {Object.entries(originLabels).map(([originKey, originValue]) => {
          return (
            <FilterCheckboxItem<number>
              key={originValue}
              value={Number(originKey)}
              label={originValue}
              status={origin}
              onAdd={addOrigin}
              onRemove={removeOrigin}
              css={styles.checkBoxStyle}
              disabled={false}
              data-trackingid={`${DATA_TRACKING_PREFIX}-origin-cbx-${originKey.toLowerCase()}`}
            />
          );
        })}
      </TableFilters.Section>

      {/* <TableFilters.Section
        sectionHeaderLabel={t('Status')}
        disableClear={!paymentStatus.length}
        onClearClick={() => setPaymentStatus([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-status`}
      >
        {Object.entries(statusLabels).map(([statusKey, statusValue]) => (
          <FilterCheckboxItem<number>
            key={statusValue}
            value={Number(statusKey)}
            label={statusValue}
            status={paymentStatus}
            onAdd={addPaymentStatus}
            onRemove={removePaymentStatus}
            css={styles.checkBoxStyle}
            disabled={false}
            data-trackingid={`${DATA_TRACKING_PREFIX}-status-cbx-${statusKey.toLowerCase()}`}
          />
        ))}
      </TableFilters.Section> */}
    </TableFilters>
  );
};
